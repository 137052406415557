import { initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from '../firebase-config'
import { register } from "register-service-worker"

export function initFirebaseSdk() {
    return new Promise(resolve => {
        if (!getApps().length) {
            initializeApp(firebaseConfig);  
        }

        register("firebase-messaging-sw.js", {
            ready() {
                //console.log("Service worker is active.");
            },
            registered() {
                //console.log("Service worker has been registered.");
            },
            cached() {
                //console.log("Content has been cached for offline use.");
            },
            updatefound() {
                //console.log("New content is downloading.");
            },
            updated() {
                //console.log("New content is available; please refresh.");
            },
            offline() {
                //console.log("No internet connection found. App is running in offline mode.");
            },
            error(error) {
                //console.error("Error during service worker registration:", error);
            },
        })

        resolve();
    });
}