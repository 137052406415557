import { createStore } from "vuex";
import { auth } from "./auth.module";
import { STN } from "@/modules/init_utilities.js";

const store = createStore({
    modules: {
        auth,
    },

    // user-defined global state variables
    // https://vuex.vuejs.org/guide/state.html
    state: {
        google: null,
        initialized: false,
        routeParams: null, 
        isShopsNavOpen: false,
        isAccountNavOpen: false,
        isHelpNavOpen: false,
        isMobileNavOpen: false,
        pageTitle: "Login",
        shopSearchResults: [],
        cachedShopData: [],
        shopsNearMeResults: [],
        authHeader: null,
        forumAuthHeader: null,
        user: null,
        applicant: null,
        backgroundMessagePayloadParams: null,  // used to store any url the user first tried to get to, but was redirected to login page (will arrive here after logging in)
        currentQuestionnaireAnswers: [],
        currentAssignmentID: null,
        gigspotAssignmentOnHold: null,
        isBestmarkManualLogin: false,
    },

    // user-defined getters are exposed and accessible via this.$store.getters......
    // https://vuex.vuejs.org/guide/getters.html
    getters: {
        // google maps
        getGoogle: (state) => {
            return state.google;
        },
        isInitialized: (state) => {
            return state.initialized;
        },


        // routing
        getRouteParams: (state) => {
            if (!STN.isBlank(state.routeParams)){
             return state.routeParams;
            } else {
                return [];
            }
        },


        // nav
        isShopsNavOpen: (state) => {
            return state.isShopsNavOpen;
        },
        isAccountNavOpen: (state) => {
            return state.isAccountNavOpen;
        },
        isHelpNavOpen: (state) => {
            return state.isHelpNavOpen;
        },
        isMobileNavOpen: (state) => {
            return state.isMobileNavOpen;
        },


        // program
        getApplicant: (state) => {
            return state.applicant;
        },

        getAuthHeader: (state) => {
            return state.authHeader;
        },

        getCurrentAssignmentID: (state) => {
            return state.currentAssignmentID;
        },

        getForumAuthHeader: (state) => {
            return state.forumAuthHeader;
        },

        getUser: (state) => {
            return state.user;
        },

        getPageTitle: (state) => {
            return state.pageTitle;
        },


        // data
        getBackgroundMessagePayloadParams: (state) => {
            return state.backgroundMessagePayloadParams;
        },
        
        getGigspotAssignmentOnHold: (state) => {
            return state.gigspotAssignmentOnHold;
        },

        getIsBestmarkManualLogin: (state) => {
            return state.isBestmarkManualLogin;
        },
        
        getShopSearchResults: (state) => {
            return state.shopSearchResults;
        },

        getShopDataFromCache: (state) => {
            return state.cachedShopData;
        },

        getShopsNearMeResults: (state) => {
            return state.shopsNearMeResults;
        },

        getCurrentQuestionnaireAnswers: (state) => {
            return state.currentQuestionnaireAnswers;
        },
    },

    //user-defined mutations are the only way to change state in vuex
    // https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
    mutations: {
        // google maps
        setGoogle(state, google) {
            state.google = google;
        },
        setInitialized(state, initialized) {
            state.initialized = initialized;
        },


        // routing
        setRouteParams(state, in_params) {
            state.routeParams = in_params;
        },


        // nav
        setIsShopsNavOpen(state, in_truefalse) {
            state.isShopsNavOpen = in_truefalse;
        },
        toggleShopsNav(state) {
            state.isShopsNavOpen = !store.isShopsNavOpen;
        },
        setIsAccountNavOpen(state, in_truefalse) {
            state.isAccountNavOpen = in_truefalse;
        },
        toggleAccountNav(state) {
            state.isAccountNavOpen = !store.isAccountNavOpen;
        },
        setIsHelpNavOpen(state, in_truefalse) {
            state.isHelpNavOpen = in_truefalse;
        },
        toggleHelpNav(state) {
            state.isHelpNavOpen = !store.isHelpNavOpen;
        },
        setIsMobileNavOpen(state, in_truefalse) {
            state.isMobileNavOpen = in_truefalse;
        },
        toggleMobileNav(state) {
            state.isMobileNavOpen = !store.isMobileNavOpen;
        },


        // program
        setApplicant(state, in_applicant) {
            state.applicant = in_applicant;
        },

        setCurrentAssignmentID(state, in_currentAssignmentID) {
            state.currentAssignmentID = in_currentAssignmentID;
        },

        setAuthHeader(state, in_authHeader) {
            state.authHeader = in_authHeader;
        },

        setUser(state, in_user) {
            state.user = in_user;
            localStorage.setItem("User",  JSON.stringify(in_user));
        },

        setPageTitle(state, in_pageTitle) {
            state.pageTitle = in_pageTitle;
        },

                
        // data
        setBackgroundMessagePayloadParams(state, in_payloadParams) {
            state.backgroundMessagePayloadParams = in_payloadParams;
        },

        setGigspotAssignmentOnHold(state, in_gigspotAssignmentOnHold) {
            state.gigspotAssignmentOnHold = in_gigspotAssignmentOnHold;
        },

        setIsBestmarkManualLogin: (state, in_isBestmarkManualLogin) => {
            state.isBestmarkManualLogin = in_isBestmarkManualLogin;
        },

        setShopSearchResults(state, in_shopSearchResults) {
            state.shopSearchResults = in_shopSearchResults;
        },

        setShopDataToCache(state, in_shopData) {
            state.cachedShopData = in_shopData;
            localStorage.setItem("ShopDataToCache",  JSON.stringify(in_shopData));
        },

        setShopsNearMeResults: (state, in_shopsNearMeResults) => {
            state.shopsNearMeResults = in_shopsNearMeResults;
        },

        setCurrentQuestionnaireAnswers: (state, in_currentQuestionnaireAnswers) => {
            state.currentQuestionnaireAnswers = in_currentQuestionnaireAnswers;
        },
    },

    actions: {
        async initializeGoogleMaps({ commit, state }) {
            if (state.initialized) return state.google;

            // if server request fails anywhere maps is accessed, redirect to error page
            try {
                const response = await STN.stnFetch(
                    "index.js",
                    "initializeGoogleMaps",
                    "POST",
                    "GetGoogleAPI",
                    null
                );

                if (!STN.isBlank(response)) {
                    // define googleMapsInit function globally
                    window.googleMapsInit = () => {
                        if (window.google && window.google.maps) {
                        commit('setGoogle', window.google);
                        commit('setInitialized', true);
                        } else {
                            throw new Error("Google Maps API is not properly loaded.");
                        }
                    };

                    // create and append the script element
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.text = response; // Set the response as script content
                    document.head.appendChild(script);

                    // wait for the script to load and execute
                    await new Promise((resolve, reject) => {
                        script.onload = () => {
                        if (state.google && state.google.maps) {
                            resolve(state.google);
                        } else {
                            reject(new Error("Google Maps API is not properly loaded."));
                        }
                        };
                        script.onerror = () => reject(new Error("Error loading Google Maps API script."));
                    });
                } else {
                    throw("initializeGoogleMaps: blank response");
                }  
            } catch (e) {
                STN.logError("index.js", "initializeGoogleMaps:  " + e, false, true, '209');
            }
        }
    }
});

export default store;