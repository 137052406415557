<template>
<!-- *********************************************************************************** DESKTOP MENU-->
<header class="hide_on_mobile">
    <div class="row" style="margin:auto;">
        <!-- logo -->
        <div class="logo_desktop">
            <a v-on:click="goDashboard()"><img src="../../assets/images/logo_desktop.png" srcset="../../assets/images/logo_desktop.png 1x, ../../assets/images/logo_desktop_2x.png 2x" alt="Second to None" /></a>
        </div>

        <!-- not logged in -->
        <div class="menus" v-if="!isLoggedIn">
            <nav class="secondary_menu">
                <ul>
                    <li class="header_phone">&nbsp;</li>
                </ul>
            </nav>
            <nav class="primary_menu">
                <ul>
                    <li class="menu-item"><a style="cursor:pointer" v-on:click="STN.redirect('helpdesk')">Help</a></li>
                </ul>
            </nav>
        </div>

        <!-- logged in -->
        <div class="menus" v-if="isLoggedIn">
            <nav class="secondary_menu">
                <ul>
                    <li class="header_phone">&nbsp;</li>
                </ul>
            </nav>
            <nav class="primary_menu">
                <ul>
                    <li class="menu-item"><a style="cursor:pointer" v-on:click="goDashboard()">My Dashboard</a></li>
                    <li v-on:click="toggleShopsNav" class="menu-item menu-item-expandable">
                        <a>shops</a>
                    </li>
                    <li class="menu-item"><a style="cursor:pointer" v-on:click="goMessages()">Messages</a></li>
                    <li v-on:click="toggleAccountNav" class="menu-item menu-item-expandable">
                        <a>my account</a>
                    </li>
                    <li v-on:click="toggleHelpNav" class="menu-item menu-item-expandable">
                        <a>help</a>
                    </li>
                    <li><a style="cursor:pointer" v-on:click="goLogOff()">Logout</a></li>
                </ul>
            </nav>
        </div>
    </div>
</header>

<!-- SHOPS DROPDOWN MENU-->
<div id="menu_shops" v-show="isShopsNavOpen" v-on:click="toggleShopsNav">
    <div class="row" style="margin: auto">
        <div class="super_left">
            <h4>SHOPS</h4>
            <nav class="super_menu">
                <ul>
                    <li><a v-on:click="goFindShops()">find shops</a></li>
                    <li><a v-on:click="goSubmitShops()">submit shops</a></li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<!-- MY ACCOUNT DROPDOWN MENU-->
<div id="menu_myaccount" v-show="isAccountNavOpen" v-on:click="toggleAccountNav">
    <div class="row" style="margin: auto">
        <div class="super_left">
            <h4>MY ACCOUNT</h4>
            <nav class="super_menu">
                <ul>
                    <li><a v-on:click="goMyAccount()">General Profile</a></li>
                    <li><a v-on:click="goMyAccountExtended()">Extended Profile</a></li>
                    <li><a v-on:click="goMyAccountPayment()">Payment Info</a></li>
                    <li><a v-on:click="goMyAccountTax()">Tax Info</a></li>
                    <li><a v-on:click="goMyAccountPhotoID()">Photo ID</a></li>
                    <li v-show="!isOauthUser"><a v-on:click="goMyAccountChangePassword()">Change Password</a></li>
                    <li><a v-on:click="goMyAccountEmailSettings()">Email Preferences</a></li>
                    <li><a v-on:click="goMyAccountNotificationSettings()">Notification Preferences</a></li>
                    <li><a v-on:click="goMyAccountDeactivate()">Deactivate Account</a></li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<!-- HELP DROPDOWN MENU-->
<div id="menu_help" v-show="isHelpNavOpen" v-on:click="toggleHelpNav">
    <div class="row" style="margin: auto">
        <div class="super_left">
            <h4>HELP</h4>
            <nav class="super_menu">
                <ul>
                    <li><a v-on:click="goHelp()">HelpDesk</a></li>
                    <li><a v-on:click="goHelpFAQ()">FAQ</a></li>
                    <li><a v-on:click="goHelpShopperGuide()">Shopper Guide from QA</a></li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<!-- *********************************************************************************** MOBILE MENU-->
<div class="hide_on_desktop header_mobile">
    <div class="logo_mobile">
        <a v-on:click="goDashboard()"><img src="../../assets/images/logo_mobile.png" srcset="../../assets/images/logo_mobile.png 1x, ../../assets/images/logo_mobile_2x.png 2x" alt="CX Group" /></a>
    </div>
    <div class="hamburger" v-on:click="openMobileNav" v-show="!isMobileNavOpen"></div>
    <div class="burger_close" v-on:click="closeMobileNav" v-show="isMobileNavOpen"></div>
    <div class="row" style="margin: auto" id="mobile_menu" v-show="isMobileNavOpen">
        <!-- not logged in -->
        <ul class="mobile_menu" v-if="!isLoggedIn">
            <li style="border-top:0px !important" class="menu-item">
                <a>help</a>
                <ul class="sub-menu">
                    <li><a v-on:click="goHelp(true)">HelpDesk</a></li>
                </ul>
            </li>
        </ul>

        <!-- logged in -->
        <ul class="mobile_menu" v-if="isLoggedIn">
            <li style="border-top:0px !important" class="menu-item"><a style="cursor:pointer" v-on:click="goDashboard(true)">My Dashboard</a></li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                <a>shops</a>
                <ul class="sub-menu">
                    <li><a v-on:click="goFindShops(true)">find shops</a></li>
                    <li><a v-on:click="goSubmitShops(true)">submit shops</a></li>
                </ul>
            </li>
            <li class="menu-item"><a style="cursor:pointer" v-on:click="goMessages(true)">Messages</a></li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                <a>my account</a>
                <ul class="sub-menu">
                    <li><a v-on:click="goMyAccount(true)">General Profile</a></li>
                    <li><a v-on:click="goMyAccountExtended(true)">Extended Profile</a></li>
                    <li><a v-on:click="goMyAccountPayment(true)">Payment Info</a></li>
                    <li><a v-on:click="goMyAccountTax(true)">Tax Info</a></li>
                    <li><a v-on:click="goMyAccountPhotoID(true)">Photo ID</a></li>
                    <li v-show="!isOauthUser"><a v-on:click="goMyAccountChangePassword(true)">Change Password</a></li>
                    <li><a v-on:click="goMyAccountEmailSettings()">Email Preferences</a></li>
                    <li><a v-on:click="goMyAccountNotificationSettings(true)">Notification Preferences</a></li>
                    <li><a v-on:click="goMyAccountDeactivate(true)">Deactivate Account</a></li>
                </ul>
            </li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                <a>help</a>
                <ul class="sub-menu">
                    <li><a v-on:click="goHelp(true)">HelpDesk</a></li>
                    <li><a v-on:click="goHelpFAQ(true)">FAQ</a></li>
                    <li><a v-on:click="goHelpShopperGuide(true)">Shopper Guide from QA</a></li>
                </ul>
            </li>
        </ul>
    </div>
</div>

<!-- BIG ORANGE BAR / HEADER -->
<section class="product_header" v-if="isLoggedIn">
    <div class="row" style="margin: auto">
        <div class="tagline">{{ this.$store.getters.getPageTitle }}</div>
    </div>
</section>
<div class="dvTopMessage" v-if="isLoggedIn" :style="createRowStyle" v-html="this.alertMessage">
</div>
</template>

<script>
export default {
    name: 'Layout_Header',
    data() {
        return {
            alertMessage: "",
        }
    },
    computed: {
        // isPasswordResetPage() {
        //     return (this.$store.getters.getPageTitle == "Retrieve Password"
        //         || this.$store.getters.getPageTitle == "maintenance"
        //         || this.$store.getters.getPageTitle == "Maintenance");
        // },
        createRowStyle() {
            if (this.STN.isBlank(this.alertMessage)) {
                return {
                    padding: "0px",
                };
            } else {
                return {
                    padding: "20px",
                };
            }
        },
        isShopsNavOpen() {
            return this.$store.getters.isShopsNavOpen;
        },
        isAccountNavOpen() {
            return this.$store.getters.isAccountNavOpen;
        },
        isHelpNavOpen() {
            return this.$store.getters.isHelpNavOpen;
        },
        isMobileNavOpen() {
            return this.$store.getters.isMobileNavOpen;
        },
        isLoggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        isOauthUser() {
            let l_oUserData = this.$store.getters.getUser;
            if (!this.STN.isBlank(l_oUserData)) {
                if (l_oUserData.LoginSource == "Apple" || l_oUserData.LoginSource == "Google" || l_oUserData.LoginSource == "Facebook") {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },
    methods: {
        ////////////////////////////////////////////////// methods
        async getAlerts() {
            let l_oUserData = await this.$store.getters.getUser;
            if (!this.STN.isBlank(l_oUserData)) {
                // do not show alerts to Bestmark NV users
                if (await this.STN.isVendorLogin() == true) {
                    this.alertMessage = "";
                } else {
                    if (l_oUserData.MaintenanceWarnOrLogoff == "W") // warn
                    {
                        this.alertMessage = "Site Maintenance will begin at 12:00am EDT, and conclude at approximately 12:15am EDT - you will be logged out of the site during this time";
                    } else if (l_oUserData.HasReportsInError == true) {
                        this.alertMessage = "You have reports that require immediate attention, please click <a href='#myshops_attention'>here</a> to resolve issues";
                    } else if (!this.STN.isBlank(l_oUserData.GeneralMessage)) {
                        if (l_oUserData.GeneralMessage == 'Update PhotoID') {
                            this.alertMessage = "Please click <a href='#myaccount_id'>here</a> to update your PhotoID";
                        } else {
                            this.alertMessage = l_oUserData.GeneralMessage;
                        }
                    } else {
                        this.alertMessage = "";
                    }
                }
            } else {
                this.alertMessage = "";
            }
        },
        ////////////////////////////////////////////////// events
        openMobileNav() {
            // couldn't get toggle working, so...
            this.$store.commit('setIsMobileNavOpen', true);
        },
        closeMobileNav() {
            // couldn't get toggle working, so...
            this.$store.commit('setIsMobileNavOpen', false);
        },
        toggleShopsNav() {
            if (this.$store.getters.isShopsNavOpen) {
                this.$store.commit('setIsShopsNavOpen', false);
            } else {
                this.$store.commit('setIsAccountNavOpen', false);
                this.$store.commit('setIsHelpNavOpen', false);
                this.$store.commit('toggleShopsNav');
            }
        },
        toggleAccountNav() {
            if (this.$store.getters.isAccountNavOpen) {
                this.$store.commit('setIsAccountNavOpen', false);
            } else {
                this.$store.commit('setIsShopsNavOpen', false);
                this.$store.commit('setIsHelpNavOpen', false);
                this.$store.commit('toggleAccountNav');
            }
        },
        toggleHelpNav() {
            if (this.$store.getters.isHelpNavOpen) {
                this.$store.commit('setIsHelpNavOpen', false);
            } else {
                this.$store.commit('setIsShopsNavOpen', false);
                this.$store.commit('setIsAccountNavOpen', false);
                this.$store.commit('toggleHelpNav');
            }
        },
        closeAllNavDropdowns() {
            this.$store.commit('setIsShopsNavOpen', false);
            this.$store.commit('setIsAccountNavOpen', false);
            this.$store.commit('setIsHelpNavOpen', false);
            this.$store.commit('setIsMobileNavOpen', false);
        },
        goAlerts() {
            this.STN.redirect('myaccount_alerts');
        },
        goDashboard(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.closeAllNavDropdowns();
            }
            this.STN.redirect('dashboard');
        },
        goFindShops(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleShopsNav');
            }
            this.$store.commit('setShopSearchResults', []);
            this.STN.redirect("findshops_results_map");
        },
        goSubmitShops(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleShopsNav');
            }
            this.STN.redirect('myshops_scheduled');
        },
        goMessages(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.closeAllNavDropdowns();
            }
            this.STN.redirect('messages');
        },
        goMyAccount(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_general');
        },
        goMyAccountDeactivate(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_deactivate');
        },
        goMyAccountExtended(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_extended');
        },
        goMyAccountEmailSettings(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_emailsettings');
        },
        goMyAccountNotificationSettings(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_notificationsettings');
        },
        goMyAccountPayment(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_payment');
        },
        goMyAccountTax(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_tax');
        },
        goMyAccountPhotoID(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_photoid');
        },
        goMyAccountChangePassword(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleAccountNav');
            }
            this.STN.redirect('myaccount_changepassword');
        },
        goHelp(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleHelpNav');
            }
            this.STN.redirect('helpdesk');
        },
        goHelpFAQ(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleHelpNav');
            }
            this.STN.redirect('helpdesk_faq');
        },
        goHelpShopperGuide(p_bIsMobile) {
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.$store.commit('toggleHelpNav');
            }
            window.open('pdf/Shopper_Guide_from_QA.pdf', '_blank');
        },
        async goLogOff(p_bIsMobile) {
            this.alertMessage = "";
            if (p_bIsMobile) {
                this.closeMobileNav();
            } else {
                this.closeAllNavDropdowns();
            }
            localStorage.setItem("RememberMe", null);
            await this.$store.dispatch('auth/logout');
            this.STN.redirect('login');
        },
    },
    updated() {
        this.getAlerts();
    },
}
</script>
